import { render, staticRenderFns } from "./childFloor.vue?vue&type=template&id=e5864a54&scoped=true&"
import script from "./childFloor.vue?vue&type=script&lang=js&"
export * from "./childFloor.vue?vue&type=script&lang=js&"
import style0 from "./childFloor.vue?vue&type=style&index=0&id=e5864a54&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5864a54",
  null
  
)

export default component.exports